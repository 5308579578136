import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/mountain/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/mountain/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/Banner-Mountain-ProductPage.jpg"
                    data-mobile="/assets/images/Banner-Mobile_Mountain.png"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> MOUNTAIN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>
                        Protezione speciale per le condizioni di sole, freddo e
                        vento ad alta quota
                      </h3>
                      <p>
                        Usi la protezione solare dell’estate anche in montagna?
                        Le condizioni ad alta quota richiedono una protezione
                        speciale. La gamma PIZ BUIN<sup>®</sup> MOUNTAIN è stata
                        creata specificatamente per tali condizioni. Tutti i
                        prodotti** contengono un’innovativa formulazione che
                        combina il filtro solare avanzato UVA / UVB e un
                        estratto di Stella Alpina che aiuta a proteggere la tua
                        pelle dal sole e dai danni a lungo termine. Arricchiti
                        con il Cold Shield Complex, tutti i prodotti**
                        assicurano un’idratazione a lunga durata e proteggono la
                        pelle dal freddo estremo e dal vento. PIZ BUIN
                        <sup>®</sup> MOUNTAIN, protezione speciale* per
                        condizioni di sole, freddo e vento ad alta quota.
                      </p>
                      <p className="footNote">
                        * Creata per proteggere da sole, freddo e vento
                        <br />
                        ** Fatta eccezione per il Lipstick
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="crema-solare" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/4634100-Mountain-Cream-SPF50+-.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>CREMA SOLARE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Questa crema per il viso ad assorbimento rapido*,
                          resistente al sudore e all'acqua, è stata creata
                          specificamente per proteggere la tua pelle
                          dall'implacabile esposizione al sole, al freddo e al
                          vento ad alta quota. Il tubetto ultra sottile ed
                          ergonomico entra facilmente in tasca. Sempre a portata
                          di mano, ma mai d'intralcio, ti permette di muoverti
                          al meglio e di goderti la neve e il sole.
                          <br />
                          <a href="/it/know_the_sun/#mountain">
                            Perchè la pelle ha bisogno di una cura speciale in
                            condizioni di alta quota?
                          </a>
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato. Evitare l'esposizione al
                          sole nelle ore centrali del giorno. Tenere neonati e
                          bambini al riparo dalla luce diretta del sole.
                        </p>
                        <p className="footNote">
                          *Ad eccezione della crema SPF50+
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina attira l’acqua e la trattiene
                                    nella pelle aiutandola a mantenere un buon
                                    livello di idratazione.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Cold Shield Complex
                                <div className="textBlock bottom">
                                  <p>
                                    Il Cold Shield Complex assicura
                                    un’idratazione a lunga durata e protegge la
                                    pelle da vento e freddo estremi. Contiene:
                                    Trehalose, Burro di Karitè, Glicerina
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4a08f11c5a84c8890abab5c8850f2fe6_f39.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Burro di Karitè
                                <div className="textBlock bottom">
                                  <p>
                                    Il burro di karitè viene ricavato
                                    dall'albero di noci di karitè che cresce in
                                    Africa occidentale. Viene utilizzato da
                                    secoli per idratare e proteggere la pelle da
                                    sole, vento, caldo e acqua di mare. Viene
                                    inoltre largamente utilizzato in cosmetica
                                    per le sue proprietà lenitive, idratanti e
                                    protettive.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Trehalose
                                <div className="textBlock bottom">
                                  <p>
                                    Il Trehalose è un disaccaride che si trova
                                    comunemente in natura e in una grande
                                    varietà di alimenti quotidiani e di animali.
                                    Si ritiene che protegga gli organismi
                                    viventi dalle condizioni ambientali ostili
                                    come il freddo estremo.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/12e0f2e932194e93854b001526d89faa_f48.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Stella Alpina
                                <div className="textBlock bottom">
                                  <p>
                                    Conosciuta come la “stella bianca dei
                                    ghiacciai” possiede un forte sistema di
                                    difesa naturale che le permette di
                                    sopravvivere all’intensa luce solare, al
                                    freddo estremo e al vento tipici degli
                                    ambienti alpini.
                                  </p>
                                  <p>
                                    L’estratto di Stella Alpina contribuisce a
                                    neutralizzare i radicali liberi generati
                                    nella pelle*, aiutandola a preservare le sue
                                    difese contro le radiazioni UV.
                                  </p>
                                  <p className="footNote">*Test in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/76186a208bd7eda9cefbe98fc7c193b8_f41.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="crema-solare-stick-labbra" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/mountain-lipstick-new.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>CREMA SOLARE + STICK LABBRA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Questa crema per il viso ad assorbimento rapido*
                          resistente al sudore e all'acqua, è stata creata
                          specificamente per proteggere la tua pelle
                          dall'implacabile esposizione al sole, al freddo e al
                          vento ad alta quota. Lo stick labbra protegge le tue
                          labbra dai pericolosi effetti dell'esposizione ai
                          raggi UVA/UVB e fornisce un'idratazione di 24 ore. Il
                          conveniente astuccio Crema solare + stick labbra è
                          ispirato agli sport invernali. Entra facilmente in
                          tasca e può essere utilizzato in ogni momento.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato. Evitare l'esposizione al
                          sole nelle ore centrali del giorno. Tenere neonati e
                          bambini al riparo dalla luce diretta del sole.
                        </p>
                        <p className="footNote">
                          *Ad eccezione della crema SPF 50+
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina attira l’acqua e la trattiene
                                    nella pelle aiutandola a mantenere un buon
                                    livello di idratazione.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Cold Shield Complex
                                <div className="textBlock bottom">
                                  <p>
                                    Il Cold Shield Complex assicura
                                    un’idratazione a lunga durata e protegge la
                                    pelle da vento e freddo estremi. Contiene:
                                    Trehalose, Burro di Karitè, Glicerina
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4a08f11c5a84c8890abab5c8850f2fe6_f39.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Burro di Karitè
                                <div className="textBlock bottom">
                                  <p>
                                    Il burro di karitè viene ricavato
                                    dall'albero di noci di karitè che cresce in
                                    Africa occidentale. Viene utilizzato da
                                    secoli per idratare e proteggere la pelle da
                                    sole, vento, caldo e acqua di mare. Viene
                                    inoltre largamente utilizzato in cosmetica
                                    per le sue proprietà lenitive, idratanti e
                                    protettive.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Trehalose
                                <div className="textBlock bottom">
                                  <p>
                                    Il Trehalose è un disaccaride che si trova
                                    comunemente in natura e in una grande
                                    varietà di alimenti quotidiani e di animali.
                                    Si ritiene che protegga gli organismi
                                    viventi dalle condizioni ambientali ostili
                                    come il freddo estremo.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/12e0f2e932194e93854b001526d89faa_f48.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Stella Alpina
                                <div className="textBlock bottom">
                                  <p>
                                    Conosciuta come la “stella bianca dei
                                    ghiacciai” possiede un forte sistema di
                                    difesa naturale che le permette di
                                    sopravvivere all’intensa luce solare, al
                                    freddo estremo e al vento tipici degli
                                    ambienti alpini.
                                  </p>
                                  <p>
                                    L’estratto di Stella Alpina contribuisce a
                                    neutralizzare i radicali liberi generati
                                    nella pelle*, aiutandola a preservare le sue
                                    difese contro le radiazioni UV.
                                  </p>
                                  <p className="footNote">*Test in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/76186a208bd7eda9cefbe98fc7c193b8_f41.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="stick-labbra" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/7371504-Lip-Stick-SPF30-.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>STICK LABBRA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          PIZ BUIN<sup>®</sup> MOUNTAIN stick labbra è
                          specificamente formulato per le condizioni di sole,
                          freddo e vento ad alta quota. Ti aiuta a proteggere le
                          labbra dal sole e offre una idratazione intensa.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato. Evitare l'esposizione al
                          sole nelle ore centrali del giorno. Tenere neonati e
                          bambini al riparo dalla luce diretta del sole.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina attira l’acqua e la trattiene
                                    nella pelle aiutandola a mantenere un buon
                                    livello di idratazione.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="products-page__product clearfix">
                    <a name="glacier-cream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/glacier-cream-product.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>Piz Buin® Glacier cream</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Ispirata alla prima crema solare PIZ BUIN<sup>®</sup>{" "}
                          lanciata nel 1946, la “Glacier Cream” aiuta a
                          proteggere la tua pelle da un'intensa esposizione ai
                          raggi UVA/UVB. Questa crema senza acqua crea una
                          barriera protettiva e intensamente idratante.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          frequentemente, soprattutto dopo aver sudato, nuotato
                          o essersi asciugati con l’asciugamano. Evitare
                          l’esposizione al sole nelle ore centrali del giorno ed
                          evitare di rimanere troppo tempo al sole anche se si
                          usano creme protettive. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole. Indicata per l’uso
                          in basse temperature.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/it/prodotti/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/active-protect/" target="_self">
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/it/prodotti/mountain/"
                      target="_self"
                    >
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Leggi di piu´');\n} else {\nbox.slideDown();\n$(this).text('Leggi meno');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
